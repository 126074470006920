import React, { useState, useEffect, useCallback } from "react";
import yaml from "js-yaml";

function App() {
  const [inputText, setInputText] = useState("");
  const [outputFormat, setOutputFormat] = useState("json");
  const [outputJson, setOutputJson] = useState("");
  const [outputYaml, setOutputYaml] = useState("");
  const [error, setError] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [theme, setTheme] = useState("dark");

  const convertText = useCallback(() => {
    if (!inputText.trim()) {
      setOutputJson("");
      setOutputYaml("");
      setError("");
      return;
    }

    try {
      setError(""); // Clear previous errors
      const lines = inputText.split("\n");
      const result = {};
      let lastPrefix = "";

      lines.forEach((line, lineNumber) => {
        line = line.trim();

        // Ignore comments and empty lines
        if (line.startsWith("#") || line === "") {
          return; // Skip processing for comments or blank lines
        }

        // Handle key-value pairs
        let key, value;
        if (line.startsWith(".")) {
          const inferredLine = lastPrefix + line.substring(1);
          [key, value] = inferredLine.split(":");
        } else {
          [key, value] = line.split(":");
          if (!key || !value) {
            throw new Error(`Line ${lineNumber + 1}: Invalid format - "${line}"`);
          }
          lastPrefix = key.substring(0, key.lastIndexOf(".") + 1);
        }

        // Handle arrays in value
        if (value.startsWith("[") && value.endsWith("]")) {
          value = value
            .substring(1, value.length - 1)
            .split(",")
            .map((v) => v.trim());
        }

        // JSONPath validation
        if (!/^[a-zA-Z0-9._[\]]+$/.test(key)) {
          throw new Error(
            `Line ${lineNumber + 1}: Invalid JSONPath/YAMLPath key - "${key}"`
          );
        }

        // Build the nested structure
        const keys = key.trim().split(".");
        let current = result;

        keys.forEach((k, i) => {
          k = k.trim();
          if (i === keys.length - 1) {
            if (current[k] !== undefined) {
              if (Array.isArray(current[k])) {
                current[k].push(value);
              } else {
                current[k] = [current[k], value];
              }
            } else {
              current[k] = value;
            }
          } else {
            current = current[k] = current[k] || {};
          }
        });
      });

      setOutputJson(JSON.stringify(result, null, 2));

      // Validate YAML
      try {
        const yamlOutput = yaml.dump(result);
        setOutputYaml(yamlOutput);
      } catch (yamlError) {
        throw new Error("Generated YAML is invalid.");
      }
    } catch (err) {
      setOutputJson("");
      setOutputYaml("");
      setError(err.message);
    }
  }, [inputText]);



  useEffect(() => {
    const debounce = setTimeout(() => convertText(), 300);
    return () => clearTimeout(debounce);
  }, [convertText]);

  const resetFields = () => {
    setInputText("");
    setOutputJson("");
    setOutputYaml("");
    setError("");
    setCopySuccess("");
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content).then(() => {
      setCopySuccess("Copied!");
      setTimeout(() => setCopySuccess(""), 2000); // Clear success message after 2 seconds
    });
  };

  const downloadOutput = (format) => {
    const content = format === "json" ? outputJson : outputYaml;
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `output.${format}`;
    link.click();
  };

  const loadExample = () => {
    setInputText(
      `# This is a comment explaining the structure
response.person.name:John
.name:Jack
.role:test
response.id:[123, 234]
# End of the example
`
    );
  };

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  return (
    <div
      style={{
        padding: "20px",
        fontFamily: "'Lucida Console', Courier, monospace",
        backgroundColor: theme === "light" ? "#f9f4e7" : "#2e2e2e",
        color: theme === "light" ? "#333" : "#f5f5f5",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <h1
        style={{
          fontSize: "2rem",
          color: theme === "light" ? "#4a4a4a" : "#e0e0e0",
          textShadow: theme === "light" ? "1px 1px #ccc" : "1px 1px #000",
          marginBottom: "10px",
        }}
      >
        Text to Json/YAML Converter
      </h1>
      <button
        onClick={toggleTheme}
        style={{
          alignSelf: "flex-end",
          padding: "5px 10px",
          fontSize: "1rem",
          fontWeight: "bold",
          cursor: "pointer",
          backgroundColor: theme === "light" ? "#ffd27f" : "#555",
          color: theme === "light" ? "#000" : "#fff",
          border: "2px solid #4a4a4a",
          borderRadius: "5px",
          fontFamily: "'Lucida Console', Courier, monospace",
        }}
      >
        Toggle {theme === "light" ? "Dark" : "Light"} Mode
      </button>



      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "20px",
          width: "90%",
          maxWidth: "1200px",
        }}
      >
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme === "light" ? "#fffbe6" : "#3e3e3e",
            border: "2px solid #4a4a4a",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "5px 5px #d3d3d3",
            height: "500px",
          }}
        >
          <h3 style={{ marginBottom: "10px", fontWeight: "bold" }}>Input Text</h3>
          <textarea
            placeholder="Enter text (e.g., response.person.name:John)"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            style={{
              flex: 1, // Grow to fill container
              resize: "none",
              fontFamily: "'Lucida Console', Courier, monospace",
              fontSize: "1.2rem",
              backgroundColor: theme === "light" ? "#fffdfa" : "#555",
              color: theme === "light" ? "#000" : "#fff",
              border: "1px solid #4a4a4a",
              borderRadius: "5px",
              padding: "10px",
              boxSizing: "border-box",
              width: "100%",
              minHeight: "200px", // Increase minimum height
              height: "100%", // Expand to fit container
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <button
              onClick={resetFields}
              style={{
                padding: "10px 20px",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: "pointer",
                backgroundColor: "#ffd27f",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontFamily: "'Lucida Console', Courier, monospace",
                boxShadow: "2px 2px #d3d3d3",
              }}
            >
              Reset
            </button>
            <button
              onClick={loadExample}
              style={{
                padding: "10px 20px",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: "pointer",
                backgroundColor: "#ffd27f",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontFamily: "'Lucida Console', Courier, monospace",
                boxShadow: "2px 2px #d3d3d3",
              }}
            >
              Load Example
            </button>
          </div>
        </div>


        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme === "light" ? "#fffbe6" : "#3e3e3e",
            border: "2px solid #4a4a4a",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "5px 5px #d3d3d3",
            height: "500px",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <button
              onClick={() => setOutputFormat("json")}
              style={{
                padding: "8px 15px",
                backgroundColor:
                  outputFormat === "json"
                    ? "#ffd27f"
                    : theme === "light"
                      ? "#fffdfa"
                      : "#555",
                color: theme === "light" ? "#000" : "#fff",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontSize: "1rem",
                fontFamily: "'Lucida Console', Courier, monospace",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              JSON
            </button>
            <button
              onClick={() => setOutputFormat("yaml")}
              style={{
                padding: "8px 15px",
                backgroundColor:
                  outputFormat === "yaml"
                    ? "#ffd27f"
                    : theme === "light"
                      ? "#fffdfa"
                      : "#555",
                color: theme === "light" ? "#000" : "#fff",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontSize: "1rem",
                fontFamily: "'Lucida Console', Courier, monospace",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              YAML
            </button>
          </div>
          <textarea
            value={outputFormat === "json" ? outputJson : outputYaml}
            readOnly
            style={{
              flex: 1,
              resize: "none",
              fontFamily: "'Lucida Console', Courier, monospace",
              fontSize: "1.2rem",
              backgroundColor: theme === "light" ? "#fffdfa" : "#555",
              color: theme === "light" ? "#000" : "#fff",
              border: "1px solid #4a4a4a",
              borderRadius: "5px",
              padding: "10px",
              boxSizing: "border-box",
            }}
          />
          {error && (
            <div
              style={{
                color: "red",
                fontFamily: "'Lucida Console', Courier, monospace",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              {error}
            </div>
          )}
          <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
            <button
              onClick={() => copyToClipboard(outputFormat === "json" ? outputJson : outputYaml)}
              style={{
                padding: "10px 20px",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: "pointer",
                backgroundColor: "#ffd27f",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontFamily: "'Lucida Console', Courier, monospace",
              }}
            >
              Copy to Clipboard
            </button>
            <button
              onClick={() => downloadOutput(outputFormat)}
              disabled={
                (outputFormat === "json" && !outputJson) ||
                (outputFormat === "yaml" && !outputYaml)
              }
              style={{
                padding: "10px 20px",
                fontSize: "1rem",
                fontWeight: "bold",
                cursor: ((outputFormat === "json" && !outputJson) || (outputFormat === "yaml" && !outputYaml)) ? "not-allowed" : "pointer",
                backgroundColor: ((outputFormat === "json" && !outputJson) || (outputFormat === "yaml" && !outputYaml)) ? "#ddd" : "#ffd27f",
                border: "2px solid #4a4a4a",
                borderRadius: "5px",
                fontFamily: "'Lucida Console', Courier, monospace",
              }}
            >
              Download {outputFormat.toUpperCase()}
            </button>


          </div>
          {copySuccess && (
            <div
              style={{
                color: "green",
                fontFamily: "'Lucida Console', Courier, monospace",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              {copySuccess}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
